import { Character } from "../character-service";
import ESI from "../esi";
import ESIService from "../esi-service";
import Observer from "./observer";

export default class WalletObserver extends Observer {
  static observer = new WalletObserver();

  public loadDataForCharacter(character : Character) : void {
    ESIService.refreshTokenIfNeeded(character.characterId).then(
      (accessToken) => {
        ESI.getCharacterWalletBalance(
          character.characterId,
          accessToken
        ).then((balance) => {
          this.setCachedResultForCharacter(character.characterId, balance);
          this.setCharacterDataLoaded(character.characterId);
        });
      }
    );
  }

  public loadTestDataForCharacter(character: Character): void {
    this.setCachedResultForCharacter(character.characterId, 123456);
    this.setCharacterDataLoaded(character.characterId);
  }
}
