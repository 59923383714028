import SkillQueueItem from "../models/esi/skill-queue-item";
import WalletTransaction from "../models/esi/wallet-transaction";

export default class TestDataHelper
{
    public static journalId = 1;
    public static transactionId = 1000;
    public static getWalletTransactionsForCharacter(character) {
        let transactions = new Array<WalletTransaction>();
        for(let i = 0; i < 100; i++)
        {
            transactions.push(TestDataHelper.buildRandomWalletTransaction());
        }
        return transactions;
    }

    private static buildRandomWalletTransaction() : WalletTransaction
    {
        let isBuy = Math.random() > 0.2;
        let isPersonal = Math.random() > 0.2;
        let dayOffset = Math.floor(Math.random() * -29);
        let hourOffset = Math.floor(Math.random() * -24);
        let type = TestDataHelper.getRandomItemType();
        let quantity = type.quantity_floor + Math.floor(Math.random() * type.quantity_floor);
        let price = isBuy ? type.price * (1 - Math.random() * 0.1) : type.price * (1 + Math.random() * 0.1);
        return {
            client_id: 1234,
            date: TestDataHelper.getTimeString(dayOffset, hourOffset),
            is_buy: isBuy,
            is_personal: isPersonal,
            journal_ref_id: TestDataHelper.journalId++,
            location_id: 60003760,
            quantity: quantity,
            transaction_id: TestDataHelper.transactionId++,
            type_id: type.type_id,
            unit_price: price,
        };
    }

    private static getRandomItemType()
    {
        let randomIndex = Math.floor(Math.random() * TestDataHelper.itemTypes.length);
        return TestDataHelper.itemTypes[randomIndex];
    }

    private static itemTypes = [
        {
            type_id: 34, // Tritanium
            price: 4.2,
            quantity_floor: 1000,
            quantity_max: 10000000,
        },
        {
            type_id: 17715, // Gila
            price: 265000000,
            quantity_floor: 1,
            quantity_max: 2,
        },
        {
            type_id: 209, // Scourge Heavy Missile
            price: 60,
            quantity_floor: 1000,
            quantity_max: 50000,
        },
        {
            type_id: 3244, // Warp Disruptor II
            price: 1200000,
            quantity_floor: 1,
            quantity_max: 1,
        },
        {
            type_id: 527, // Stasis Webifier II
            price: 1200000,
            quantity_floor: 1,
            quantity_max: 1,
        },
        {
            type_id: 2205, // Acolyte II
            price: 1200000,
            quantity_floor: 1,
            quantity_max: 1,
        },
    ]

    public static getSkillQueueForCharacter(characterId) : Array<SkillQueueItem>
    {
        let skills = new Array<SkillQueueItem>();
        for(let i = 0; i < 15; i++)
        {
            skills.push(TestDataHelper.buildRandomSkill());
        }
        return skills;
    }

    private static lastOffsetDays = 0;
    private static buildRandomSkill() : SkillQueueItem
    {
        let finishedLevel = 1 + Math.floor(Math.random() * 5);
        let newOffset = Math.floor(Math.random() * 30);
        TestDataHelper.lastOffsetDays += newOffset;
        let finishDayOffset = TestDataHelper.lastOffsetDays;
        let finishHourOffset = Math.floor(Math.random() * 24);
        let skill = TestDataHelper.getRandomSkill();
        return {
            finish_date: TestDataHelper.getTimeString(finishDayOffset, finishHourOffset),
            finished_level: finishedLevel,
            level_end_sp : 1234,
            level_start_sp : 1234,
            queue_position : "0",
            skill_id : skill.type_id,
            start_date : "1234",
            training_start_sp : 1233,
          };
    }

    private static getRandomSkill()
    {
        let randomIndex = Math.floor(Math.random() * TestDataHelper.skills.length);
        return TestDataHelper.skills[randomIndex];
    }

    private static skills = [
        {
            type_id: 3336, // Gallente Battleship
        },
        {
            type_id: 3310, // Rapid Firing
        },
        {
            type_id: 3442, // Drone Interfacing
        },
        {
            type_id: 3393, // Repair Systems
        },
    ];

    private static getTimeString(dayOffset, hourOffset)
    {
        let time = new Date();
        time.setDate(time.getDate() + dayOffset);
        time.setHours(time.getHours() + hourOffset);
        time.setMinutes(Math.floor(Math.random() * 60));
        time.setSeconds(Math.floor(Math.random() * 60));
        return time.toISOString();
    }
}