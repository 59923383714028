import TestHelper from "../../helpers/test-helper";
import CharacterService, { Character } from "../character-service";

export default class Observer {
  registeredCallbacks: Array<Function> = [];
  cachedResults: Map<number, any> = new Map<number, any>();
  inFlightRequests: Array<number> = [];
  refreshFunction: any = null;
  lastLoadTime: Date = new Date(2000, 1, 1);
  apiDebounceInterval = 60000;
  refreshDataInterval = 300000;

  public observe(callback: Function) {
    this.registeredCallbacks.push(callback);
    this.loadDataForAllCharacters();
    callback(this.cachedResults);
    if (this.refreshFunction == null) {
      this.refreshFunction = setInterval(() => {
        this.loadDataForAllCharacters();
      }, this.refreshDataInterval);
    }
  }

  public setCachedResultForCharacter(characterId: number, result: any) {
    this.cachedResults.set(characterId, result);
    this.registeredCallbacks.forEach((callback) => {
      callback(this.cachedResults);
    });
  }

  public setCharacterDataLoaded(characterId: number) {
    this.inFlightRequests = this.inFlightRequests.filter(
      (x) => x !== characterId
    );
  }

  public loadDataForAllCharacters() {
    if (
      this.lastLoadTime != null &&
      new Date().getTime() - this.lastLoadTime.getTime() <
        this.apiDebounceInterval
    ) {
      return;
    }
    CharacterService.getAllCharacters().forEach((character) => {
      if (this.inFlightRequests.includes(character.characterId)) {
        // We don't try to look up the same data twice at the same time
        return;
      }
      this.inFlightRequests.push(character.characterId);

      if(TestHelper.isDemo())
      {
        this.loadTestDataForCharacter(character);
      }else{
        this.loadDataForCharacter(character);
      }
    });
    this.lastLoadTime = new Date();
  }

  public loadDataForCharacter(character: Character) {
    // Store data in cached results for character in child class
  }

  public loadTestDataForCharacter(character: Character)
  {
    // Store mock data in cached results for character in child class
  }

  public unobserve(callbackToUnregister) {
    this.registeredCallbacks = this.registeredCallbacks.filter(
      (x) => x !== callbackToUnregister
    );
    if (this.registeredCallbacks.length === 0) {
      clearInterval(this.refreshFunction);
      this.refreshFunction = null;
    }
  }
}
