import React, { useState, useEffect } from "react";
import ISKHelper from "../helpers/isk-helper";
import WalletTransaction from "../models/esi/wallet-transaction";
import CharacterService from "../services/character-service";
import NameLookup from "../services/name-lookup-service";
import TransactionObserver from "../services/observables/transaction-observer";

export const TradeProfits = (props) => {
  const [transactions, setTransactions] = useState<
    Map<number, Array<WalletTransaction>>
  >(new Map<number, Array<WalletTransaction>>());

  useEffect(() => {
    var handler = (newTransactions) => {
      setTransactions(
        new Map<number, Array<WalletTransaction>>(newTransactions)
      );
    };
    TransactionObserver.observer.observe(handler);

    return () => {
      TransactionObserver.observer.unobserve(handler);
    };
  }, []);

  const character = CharacterService.getActiveCharacter();
  if (!character) {
    return <p>Please log in and select a character...</p>;
  }
  let transactionsForActiveCharacter = transactions.get(character.characterId);
  if (!transactionsForActiveCharacter) {
    return <p>Loading...</p>;
  }

  const transactionsByType = transactionsForActiveCharacter.reduce(
    (groupedTransactions, value) => {
      // Group initialization
      if (!groupedTransactions[value.type_id]) {
        groupedTransactions[value.type_id] = [];
      }

      // Grouping
      groupedTransactions[value.type_id].push(value);

      return groupedTransactions;
    },
    {}
  );

  let profitsByType = {};
  let totalMonthlyProfits = 0;
  let totalWeeklyProfits = 0;
  let totalDailyProfits = 0;
  Object.keys(transactionsByType).forEach(function (typeId, index) {
    let group = transactionsByType[typeId];
    let monthBuyTotal = 0;
    let monthBuyCount = 0;
    let monthSellTotal = 0;
    let monthSellCount = 0;
    let weekSellCount = 0;
    let daySellCount = 0;
    group.forEach((transaction) => {
      if (transaction.is_buy) {
        monthBuyTotal += transaction.quantity * transaction.unit_price;
        monthBuyCount += transaction.quantity;
      } else {
        monthSellTotal += transaction.quantity * transaction.unit_price;
        monthSellCount += transaction.quantity;
        var transactionDate = (new Date(transaction.date)).getDate();
        if(transactionDate > (new Date()).getDate() - 7)
        {
          weekSellCount++;
        }
        if(transactionDate > (new Date()).getDate() - 1)
        {
          daySellCount++;
        }
      }
    });
    if(monthSellCount == 0 || monthBuyCount == 0)
    {
      return;
    }
    let buyAvg = monthBuyTotal / monthBuyCount;
    let sellAvg = monthSellTotal / monthSellCount;
    let margin = sellAvg * 0.96 - buyAvg * 1.01;
    let monthlyProfits = monthBuyCount < monthSellCount ? monthBuyCount * margin : monthSellCount * margin;
    let weeklyProfits = weekSellCount * margin;
    let dailyProfits = daySellCount * margin;
    profitsByType[typeId] = {
      'monthlyProfits' : monthlyProfits,
      'weeklyProfits' : weeklyProfits,
      'dailyProfits' : dailyProfits
    };
    totalMonthlyProfits += monthlyProfits;
    totalWeeklyProfits += weeklyProfits;
    totalDailyProfits += dailyProfits;
  });

  let profitableItems = Object.keys(profitsByType).map(typeId => {
    return {
      type: typeId,
      monthlyProfits: profitsByType[typeId].monthlyProfits,
      weeklyProfits: profitsByType[typeId].weeklyProfits,
      dailyProfits: profitsByType[typeId].dailyProfits
    };
  });
  profitableItems = profitableItems.sort((a, b) => {
    return a.monthlyProfits < b.monthlyProfits ? 1 : -1;
  });

  const totalColor = totalMonthlyProfits < 0 ? "red" : "green";
  const totalStyle = {
    color: totalColor,
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Item Type</th>
            <th>Profits (30d)</th>
            <th>Profits (7d)</th>
            <th>Profits (1d)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-center">TOTAL:</td>
            <td style={totalStyle} className="align-right">{ISKHelper.formatISK(totalMonthlyProfits)}</td>
            <td style={totalStyle} className="align-right">{ISKHelper.formatISK(totalWeeklyProfits)}</td>
            <td style={totalStyle} className="align-right">{ISKHelper.formatISK(totalDailyProfits)}</td>
          </tr>
          {profitableItems.map((type) => {
            const profitsColor = type.monthlyProfits < 0 ? "red" : "green";
            const profitsStyle = {
              color: profitsColor,
            };
            return (
              <tr key={type.type}>
                <td className="align-center">
                  {NameLookup.lookupTypeName(Number(type.type))}
                </td>
                <td style={profitsStyle} className="align-right">
                  {ISKHelper.formatISK(type.monthlyProfits)}
                </td>
                <td style={profitsStyle} className="align-right">
                  {ISKHelper.formatISK(type.weeklyProfits)}
                </td>
                <td style={profitsStyle} className="align-right">
                  {ISKHelper.formatISK(type.dailyProfits)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
