import React, { useState, useEffect } from "react";
import ISKHelper from "../helpers/isk-helper";
import CharacterService from "../services/character-service";
import WalletObserver from "../services/observables/wallet-observer";
import { TradeProfits } from "./trade-profits";
import { Transactions } from "./transactions";
import { Wallet } from "./wallet";

export const Finance = (props) => {
  let style = {
    height: "300px",
    overflow: "scroll"
  };
  
  return (<div>
    <div className="flex-center">
      <Wallet></Wallet>
      <div style={style}>
        <TradeProfits></TradeProfits>
      </div>      
    </div>
    <Transactions></Transactions>
  </div>);
};
