export default class ISKHelper {
  public static formatISK(value: number) : string {
    if (typeof value !== 'number')
    {
      return "0";
    }
    var valueString = ISKHelper.addCommas(value);
    return valueString;
  }

  private static addCommas(n) : string{
    var rx=  /(\d+)(\d{3})/;
    return String(n.toFixed(0)).replace(/^\d+/, function(w){
        while(rx.test(w)){
            w= w.replace(rx, '$1,$2');
        }
        return w;
    });
  }
}
