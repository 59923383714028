import EnvironmentHelper from "../helpers/environment-helper";
import CharacterPublicInfo from "../models/esi/character-info";
import IndustryJob from "../models/esi/industry-job";
import Structure from "../models/esi/structure";
import SkillQueueItem from "../models/esi/skill-queue-item";
import AlertService from "./alert-service";
import CharacterService from "./character-service";
import ESI from "./esi";
import NameLookup from "./name-lookup-service";
import { SkillsResponse } from "../models/esi/skill";

export default class ESIService {
  public static getSkillQueue(
    characterId: number,
    actAsCharacterId: number
  ): Promise<Array<SkillQueueItem>> {
    return ESIService.refreshTokenIfNeeded(actAsCharacterId).then(
      (accessToken) => {
        return ESI.getSkillQueue(characterId, accessToken).then((result) => {
          return result;
        });
      }
    );
  }

  public static getSkills(
    characterId: number,
    actAsCharacterId: number
  ): Promise<SkillsResponse> {
    return ESIService.refreshTokenIfNeeded(actAsCharacterId).then(
      (accessToken) => {
        return ESI.getSkills(characterId, accessToken);
      }
    );
  }

  public static getCharacterInfo(
    characterId: number,
    actAsCharacterId: number
  ): Promise<CharacterPublicInfo> {
    return ESI.getCharacterInfo(characterId);
  }

  public static getIndustryJobs(
    characterId: number,
    actAsCharacterId: number
  ): Promise<Array<IndustryJob>> {
    return ESIService.refreshTokenIfNeeded(actAsCharacterId).then(
      (accessToken) => {
        const jobs = ESI.getIndustryJobs(characterId, accessToken)
          .then((jobs) => {
            if(jobs.length === 0)
            {
              return [];
            }
            let idsToLookUp: Array<number> = [];
            jobs.forEach((element) => {
              idsToLookUp.push(element.blueprint_type_id);
              idsToLookUp.push(element.installer_id);
            });
            idsToLookUp = idsToLookUp.filter(function (item, pos, self) {
              return self.indexOf(item) === pos;
            });
            return NameLookup.lookupNames(idsToLookUp)
              .then((nameDictionary) => {
                jobs.forEach((job) => {
                  job.name = nameDictionary[job.blueprint_type_id];
                  job.installer_name = nameDictionary[job.installer_id]
                });
                return jobs;
              })
          });
        return jobs;
      }
    );
  }

  public static getCorporationIndustryJobs(
    corporationId: number,
    actAsCharacterId: number
  ): Promise<Array<IndustryJob>> {
    return ESIService.refreshTokenIfNeeded(actAsCharacterId).then(
      (accessToken) => {
        const jobs = ESI.getCorporationIndustryJobs(corporationId, accessToken)
          .then((jobs) => {
            if(jobs.length === 0)
            {
              return [];
            }
            let idsToLookUp: Array<number> = [];
            jobs.forEach((element) => {
              idsToLookUp.push(element.blueprint_type_id);
              idsToLookUp.push(element.installer_id);
            });
            idsToLookUp = idsToLookUp.filter(function (item, pos, self) {
              return self.indexOf(item) === pos;
            });
            return NameLookup.lookupNames(idsToLookUp)
              .then((nameDictionary) => {
                jobs.forEach((job) => {
                  job.name = nameDictionary[job.blueprint_type_id];
                  job.installer_name = nameDictionary[job.installer_id]
                });
                return jobs;
              })
          });
        return jobs;
      }
    );
  }

  public static getCorporationStructures(
    corporationId: number,
    actAsCharacterId: number
  ): Promise<Array<Structure>> {
    return ESIService.refreshTokenIfNeeded(actAsCharacterId).then(
      (accessToken) => {
        const structures = ESI.getCorporationStructures(corporationId, accessToken)
          .then((structures) => {
            if(structures.length === 0)
            {
              return [];
            }
            return structures;
          });
        return structures;
      }
    );
  }

  public static refreshTokenIfNeeded(
    actAsCharacterId: number
  ): Promise<string> {
    var promise = new Promise<string>(function (resolve, reject) {
      const character = CharacterService.getCharacter(actAsCharacterId);
      if (!character) {
        console.log("Character not found!! " + actAsCharacterId);
        throw new Error("Character not found!!");
      }

      if (new Date(character.accessTokenExpiration) < new Date()) {
        console.log("Refreshing token...");
        return ESI.refreshAccessToken(
          character.refreshToken,
          EnvironmentHelper.getEVEClientId(),
          character.corporationId
        ).then((updatedCharacter) => {
          CharacterService.addCharacter(updatedCharacter);
          resolve(updatedCharacter.accessToken);
        }).catch((error) => {
          AlertService.alert(`Error refreshing token for ${character.characterName} - ${error}`);
        });
      }
      console.log("Token still valid.");
      resolve(character.accessToken);
    });
    return promise;
  }
}
