import ESI from "./esi";

export default class NameLookup {
  static typeDictionary: any = {};
  static systemDictionary: any = {};
  static typesLoaded = false;
  static systemsLoaded = false;

  public static loadNames(callback: Function) {
    const savedSystemNames = localStorage.getItem("systemNames");
    if (savedSystemNames) {
      NameLookup.systemDictionary = JSON.parse(savedSystemNames);
      NameLookup.systemsLoaded = true;
    } else {
      ESI.getSystems().then((systems: Array<number>) => {
        const chunkSize = 1000;
        let promises = new Array<Promise<any>>();
        for (let i = 0; i < systems.length; i += chunkSize) {
          const chunk = systems.slice(i, i + chunkSize);
          promises.push(
            NameLookup.lookupNames(chunk).then((nameDictionary) => {
              Object.keys(nameDictionary).forEach((key, index) => {
                NameLookup.systemDictionary[key] = nameDictionary[key];
              });
            })
          );
        }
        Promise.all(promises).then(() => {
          NameLookup.systemsLoaded = true;
          if (NameLookup.allDictionariesLoaded()) {
            callback();
          }
          localStorage.setItem(
            "systemNames",
            JSON.stringify(NameLookup.systemDictionary)
          );
        });
      });
    }

    const savedTypeNames = localStorage.getItem("typeNames");
    if (savedTypeNames) {
      NameLookup.typeDictionary = JSON.parse(savedTypeNames);
      NameLookup.typesLoaded = true;
    } else {
      let allTypes = new Array<number>();
      let promises = new Array<Promise<any>>();
      for (var i = 1; i < 45; i++) {
        promises.push(
          ESI.getTypes(i).then((types: Array<number>) => {
            allTypes = allTypes.concat(types);
          })
        );
      }
      Promise.all(promises).then(x => {
        const chunkSize = 1000;
          let namePromises = new Array<Promise<any>>();
          for (let i = 0; i < allTypes.length; i += chunkSize) {
            const chunk = allTypes.slice(i, i + chunkSize);
            namePromises.push(
              NameLookup.lookupNames(chunk).then((nameDictionary) => {
                Object.keys(nameDictionary).forEach((key, index) => {
                  NameLookup.typeDictionary[key] = nameDictionary[key];
                });
              })
            );
          }
          Promise.all(namePromises).then(() => {
            NameLookup.typesLoaded = true;
            if (NameLookup.allDictionariesLoaded()) {
              callback();
            }
            localStorage.setItem(
              "typeNames",
              JSON.stringify(NameLookup.typeDictionary)
            );
          });
      })
    }
    if (NameLookup.allDictionariesLoaded()) {
      callback();
    }
  }

  private static allDictionariesLoaded() {
    return NameLookup.typesLoaded && NameLookup.systemsLoaded;
  }

  public static lookupTypeName(id: number): string {
    return NameLookup.typeDictionary[id];
  }

  public static lookupSystemName(id: number): string {
    return NameLookup.systemDictionary[id];
  }

  public static lookupNames(ids: Array<number>) {
    if (ids.length === 0) {
      return new Promise<any>((resolve, reject) => {
        resolve([]);
      });
    }
    // deduplicate IDs
    ids = ids.filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    });
    var promise = new Promise<any>(function (resolve, reject) {
      var xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open(
        "POST",
        "https://esi.evetech.net/latest/universe/names/"
      );

      xmlHttpRequest.onload = () => {
        let parsedResult = JSON.parse(xmlHttpRequest.responseText);
        let dictionary: any = {};
        parsedResult.forEach((element) => {
          dictionary[element.id] = element.name;
        });
        resolve(dictionary);
      };

      xmlHttpRequest.send(JSON.stringify(ids));
    });
    return promise;
  }
}
