import AccessToken from "../models/esi/access-token";

export default class AccessTokenHelper {
  public static parseAccessToken(tokenToParse: string) : AccessToken {
    var base64Url = tokenToParse.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    let decodedToken = JSON.parse(jsonPayload);
    return decodedToken;
  }
}
