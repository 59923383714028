export default class TestHelper
{
    public static isDemo() {
        const urlParams = new URLSearchParams(window.location.search);
        const isDemo = urlParams.get('demo');
        if(isDemo)
        {
            return true;
        }
        return false;
    }

    public static getDemo()
    {
        const urlParams = new URLSearchParams(window.location.search);
        const demo = urlParams.get('demo');
        return demo;
    }
}