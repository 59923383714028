import React, { useState, useEffect } from "react";
import ISKHelper from "../helpers/isk-helper";
import CharacterService from "../services/character-service";
import WalletObserver from "../services/observables/wallet-observer";

export const Wallet = (props) => {
  const [wallets, setWallets] = useState<Map<number, number>>(
    new Map<number, number>()
  );

  useEffect(() => {
    var handler = (newBalances) => {
      setWallets(new Map<number, number>(newBalances));
    };
    WalletObserver.observer.observe(handler);

    return () => {
      WalletObserver.observer.unobserve(handler);
    };
  }, []);

  const characters = CharacterService.getAllCharacters();

  let balances = new Array<any>();
  let total = 0;
  wallets.forEach((wallet, index) => {
    total += wallet;
    balances.push({
      name:
        characters.find((character) => {
          return character.characterId === index;
        })?.characterName ?? "N/A",
      balance: ISKHelper.formatISK(wallet),
    });
  });

  balances = balances.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Character</th>
            <th>Wallet Balance</th>
          </tr>
        </thead>
        <tbody>
          {balances.map(function (walletBalance: any, index: number) {
            return (
              <tr key={index}>
                <td className="align-center">{walletBalance.name}</td>
                <td className="align-right">{walletBalance.balance}</td>
              </tr>
            );
          })}
          <tr>
            <td className="align-center">TOTAL</td>
            <td className="align-right">{ISKHelper.formatISK(total)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
