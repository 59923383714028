import React, { useState, useEffect } from "react";
import CharacterService from "../services/character-service";

export const CharacterPicker = (props) => {
  const [selected, setSelected] = useState(CharacterService.getActiveCharacter()?.characterId);
  const { onChange } = props;

  const characters = CharacterService.getAllCharacters();

  if (!characters) {
    return <p>Characters not found.</p>;
  }

  const handleChange = event => {
    setSelected(event.target.value);
    if(onChange)
    {
        onChange(event.target.value);
    }
    CharacterService.setActiveCharacter(parseInt(event.target.value));
  };

  return (
    <select value={selected} onChange={handleChange}>
      {characters.map(function (character: any, index: number) {
        return (
          <option key={index} value={character.characterId}>
            {character.characterName}
          </option>
        );
      })}
    </select>
  );};
