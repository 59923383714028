export default class RelativeTime {
  public static relative(time: Date) {
    const now = new Date();
    const difference = time.getTime() - now.getTime();
    const secondsDifference = difference / 1000;
    let output = ``;
    if (secondsDifference < 60) {
      // Less than a minute has passed:
      output = `${secondsDifference} seconds from now`;
    } else if (secondsDifference < 3600) {
      // Less than an hour has passed:
      output = `${Math.floor(secondsDifference / 60)} minutes from now`;
    } else if (secondsDifference < 86400) {
      // Less than a day has passed:
      output = `${Math.floor(secondsDifference / 3600)} hours from now`;
    } else if (secondsDifference < 2620800) {
      // Less than a month has passed:
      output = `${Math.floor(secondsDifference / 86400)} days from now`;
    } else if (secondsDifference < 31449600) {
      // Less than a year has passed:
      output = `${Math.floor(secondsDifference / 2620800)} months from now`;
    } else {
      // More than a year has passed:
      output = `${Math.floor(secondsDifference / 31449600)} years from now`;
    }
    return output;
  }
}
