import React, { useState, useEffect } from "react";
import ISKHelper from "../helpers/isk-helper";
import WalletTransaction from "../models/esi/wallet-transaction";
import CharacterService from "../services/character-service";
import NameLookup from "../services/name-lookup-service";
import TransactionObserver from "../services/observables/transaction-observer";

export const Transactions = (props) => {
  const [transactions, setTransactions] = useState<
    Map<number, Array<WalletTransaction>>
  >(new Map<number, Array<WalletTransaction>>());

  useEffect(() => {
    var handler = (newTransactions) => {
      setTransactions(
        new Map<number, Array<WalletTransaction>>(newTransactions)
      );
    };
    TransactionObserver.observer.observe(handler);

    return () => {
      TransactionObserver.observer.unobserve(handler);
    };
  }, []);

  const character = CharacterService.getActiveCharacter();
  if (!character) {
    return <p>Please log in and select a character...</p>;
  }
  let transactionsForActiveCharacter = transactions.get(character.characterId);
  if (!transactionsForActiveCharacter) {
    return <p>Loading...</p>;
  }

  const transactionsByType = transactionsForActiveCharacter.reduce(
    (groupedTransactions, value) => {
      // Group initialization
      if (!groupedTransactions[value.type_id]) {
        groupedTransactions[value.type_id] = [];
      }

      // Grouping
      groupedTransactions[value.type_id].push(value);

      return groupedTransactions;
    },
    {}
  );

  let characterTransactions = transactions.get(character.characterId)?.sort((a, b) => 
  {
    return a.date < b.date ? 1 : -1;
  });

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Change</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Item Type</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {characterTransactions?.map((transaction) => {
            const amountColor = transaction.is_buy ? "red" : "green";
            const amountStyle = {
              color: amountColor,
            };
            const personalColor = transaction.is_personal ? "" : "aqua";
            const personalStyle = {
              color: personalColor,
            };
            const transactionAmount =
              transaction.quantity * transaction.unit_price;
            return (
              <tr key={transaction.transaction_id}>
                <td className="align-right" style={amountStyle}>
                  {ISKHelper.formatISK(transactionAmount)}
                </td>
                <td className="align-right" style={personalStyle}>
                  {transaction.quantity}
                </td>
                <td className="align-right" style={personalStyle}>
                  {ISKHelper.formatISK(transaction.unit_price)}
                </td>
                <td className="align-center" style={personalStyle}>
                  {NameLookup.lookupTypeName(transaction.type_id)}
                </td>
                <td style={personalStyle}>
                  {new Date(Date.parse(transaction.date)).toLocaleString()}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
