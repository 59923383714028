import React, { useState, useEffect } from "react";
import CustomsOffice from "../models/esi/customs-office";
import Structure from "../models/esi/structure";
import CharacterService from "../services/character-service";
import ESIService from "../services/esi-service";
import NameLookup from "../services/name-lookup-service";
import CustomsOfficeObserver from "../services/observables/customs-office-observer";

export const CorporationStructures = (props) => {
  const [structures, setStructures] = useState<Array<Structure>>([]);
  const [customsOfficesByCharacter, setCustomsOffices] = useState<Map<string, any>>(
    new Map<string, any>()
  );
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    var handler = (updatedCustomsOffices) => {
      setCustomsOffices(updatedCustomsOffices);
      return () => {
        CustomsOfficeObserver.observer.unobserve(handler);
      }
    };
    CustomsOfficeObserver.observer.observe(handler);
  }, []);

  const characters = CharacterService.getAllCharacters();
  let pocos = [];
  customsOfficesByCharacter.forEach(customsOfficesForCharacter => {
    pocos = pocos.concat(customsOfficesForCharacter);
  });

  if (!characters) {
    return <p>No characters found.</p>;
  }

  if (!isLoaded) {
    let promises: Array<Promise<Structure[]>> = [];
    characters.forEach((character) => {
      promises.push(
        ESIService.getCharacterInfo(
          character.characterId,
          character.characterId
        ).then((info) => {
          return ESIService.getCorporationStructures(
            info.corporation_id,
            character.characterId
          );
        })
      );
    });
    Promise.all(promises).then((promiseResults) => {
      let newStructures: Array<Structure> = [];
      promiseResults.forEach((result) => {
        newStructures = newStructures.concat(result);
      });
      setStructures(newStructures);
    });
    setIsLoaded(true);
  }

  return (
    <div>
      <h4>Stations</h4>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Fueled Until</th>
            <th>Status</th>
            <th>System</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {structures.map(function (structure: Structure, index: number) {
            return (
              <tr key={structure.structure_id}>
                <td>{structure.name}</td>
                <td>{structure.fuel_expires}</td>
                <td>{structure.state}</td>
                <td>{NameLookup.lookupSystemName(structure.system_id)}</td>
                <td>{NameLookup.lookupTypeName(structure.type_id)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <h4>Customs Offices</h4>
      <table>
        <thead>
          <tr>
            <th>Office ID</th>
            <th>System</th>
            <th>Reinforcement Window</th>
          </tr>
        </thead>
        <tbody>
          {
            pocos.map((customsOffice: CustomsOffice) => {
              return (
                <tr key={customsOffice.alliance_tax_rate}>
                  <td>{customsOffice.office_id}</td>
                  <td>{NameLookup.lookupSystemName(customsOffice.system_id)}</td>
                  <td>{customsOffice.reinforce_exit_start} - {customsOffice.reinforce_exit_end}</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};
