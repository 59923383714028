import React, { useState, useEffect } from "react";
import RelativeTime from "../helpers/relative-time-helper";
import CharacterService from "../services/character-service";
import NameLookup from "../services/name-lookup-service";
import SkillQueueObserver from "../services/observables/skill-queue-observer";
import SkillQueueItem from "../models/esi/skill-queue-item";

export const SkillQueue = (props) => {
  const [skillQueue, setskillQueue] = useState<Array<any>>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { characterId } = props;

  const character = CharacterService.getCharacter(characterId);

  useEffect(() => {
    var handler = (updatedSkillQueues) => {
      if(updatedSkillQueues.has(characterId))
      {
        var characterSkillQueue = updatedSkillQueues.get(characterId);
        characterSkillQueue.forEach(element => {
          element.name = NameLookup.lookupTypeName(element.skill_id);
        });
        setskillQueue(characterSkillQueue); 
      }
      return () => {
        SkillQueueObserver.observer.unobserve(handler);
      }
    };
    SkillQueueObserver.observer.observe(handler);
  }, [characterId]);

  if (!character) {
    return <p>Character not found.</p>;
  }

  if (skillQueue.length === 0) {
    if (isLoaded) {
      return <p>No skills in queue.</p>;
    }
  }

  return (
    <div>
      {skillQueue.map(function (skill: any, index: number) {
        var isInPast = new Date(skill.finish_date) < new Date();
        return (
          <p key={index}>
            {skill.name} level {skill.finished_level} -{" "}
            {isInPast
              ? "Finished"
              : RelativeTime.relative(new Date(skill.finish_date))}
          </p>
        );
      })}
    </div>
  );
};
