import AccessTokenHelper from "../helpers/access-token-helper";
import CharacterPublicInfo from "../models/esi/character-info";
import CustomsOffice from "../models/esi/customs-office";
import IndustryJob from "../models/esi/industry-job";
import { SkillsResponse } from "../models/esi/skill";
import SkillQueueItem from "../models/esi/skill-queue-item";
import Structure from "../models/esi/structure";
import WalletTransaction from "../models/esi/wallet-transaction";
import { Character } from "./character-service";

export default class ESI {
  public static getCharacterInfo(
    characterId: number
  ): Promise<CharacterPublicInfo> {
    return this.sendGetRequestAsync(`/characters/${characterId}`);
  }

  public static getCharacterWalletBalance(
    characterId: number,
    accessToken: string
  ): Promise<number> {
    return this.sendGetRequestAsync(`/characters/${characterId}/wallet`, accessToken);
  }

  public static getSkillQueue(
    characterId: number,
    accessToken: string
  ): Promise<Array<SkillQueueItem>> {
    return this.sendGetRequestAsync(`/characters/${characterId}/skillqueue`, accessToken);
  }

  public static getSkills(
    characterId: number,
    accessToken: string
  ): Promise<SkillsResponse> {
    return this.sendGetRequestAsync(`/characters/${characterId}/skills`, accessToken);
  }

  public static getTypes(page : number): Promise<any> {
    return this.sendGetRequestAsync(`/universe/types?page=${page}`);
  }

  public static getSystems(): Promise<any> {
    return this.sendGetRequestAsync(`/universe/systems`);
  }

  public static getIndustryJobs(
    characterId: number,
    accessToken: string
  ): Promise<Array<IndustryJob>> {
    return this.sendGetRequestAsync(`/characters/${characterId}/industry/jobs`, accessToken);
  }

  public static getCorporationIndustryJobs(
    corporationId: number,
    accessToken: string
  ): Promise<Array<IndustryJob>> {
    return this.sendGetRequestAsync(`/corporations/${corporationId}/industry/jobs`, accessToken);
  }

  public static getCorporationStructures(
    corporationId: number,
    accessToken: string
  ): Promise<Array<Structure>> {
    return this.sendGetRequestAsync(`/corporations/${corporationId}/structures`, accessToken);
  }

  public static getCustomsOffices(
    corporationId: number,
    accessToken: string
  ): Promise<Array<CustomsOffice>> {
    return this.sendGetRequestAsync(`/corporations/${corporationId}/customs_offices/`, accessToken);
  }

  public static getWalletTransactions(
    characterId: number,
    accessToken: string
  ): Promise<Array<WalletTransaction>> {
    return this.sendGetRequestAsync(`/characters/${characterId}/wallet/transactions`, accessToken);
  }

  private static sendGetRequestAsync(path : string, accessToken? : string)
  {
    var promise = new Promise<any>(function (resolve, reject) {
      var xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open(
        "GET",
        "https://esi.evetech.net/latest" + path
      );
      if(accessToken != null)
      {
        xmlHttpRequest.setRequestHeader(
          "Authorization",
          "Bearer " + accessToken
        );
      }      
      xmlHttpRequest.onload = () => {
        var result = JSON.parse(xmlHttpRequest.responseText);
        resolve(result);
      };
      xmlHttpRequest.onerror = function () {
        reject(Error("error fetching JSON data"));
      };

      xmlHttpRequest.send();
    });
    return promise;
  }

  public static refreshAccessToken(refreshToken : string, clientId: string, corporationId: number) : Promise<Character>
  {
    var promise = new Promise<Character>(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
      xhr.open("POST", "https://login.eveonline.com/v2/oauth/token");
      xhr.setRequestHeader(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );

      xhr.onload = () => {
        let response = JSON.parse(xhr.responseText);
        if(response.error_description)
        {
          reject(response.error_description);
          return;
        }
        if (!response.access_token) {
          return;
        }

        let decodedToken = AccessTokenHelper.parseAccessToken(response.access_token);
        var tokenCharacterId = decodedToken.sub ? decodedToken.sub.split(":")[2] : "Error";
        var tokenExpiration = new Date(decodedToken.exp as number * 1000);
        let characterIdNumber = parseInt(tokenCharacterId);
        
        ESI.getCharacterInfo(characterIdNumber).then((characterInfo) => {
          const character = {
            characterId: characterIdNumber,
            corporationId: characterInfo.corporation_id,
            characterName: decodedToken.name ?? "",
            accessToken: response.access_token,
            accessTokenExpiration: tokenExpiration.toISOString(),
            refreshToken: response.refresh_token,
            scopes: decodedToken.scp ?? [],
          };
          resolve(character);
        });
      };

      let data = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${clientId}`;

      xhr.send(data);
      });
    return promise;    
  }
}
