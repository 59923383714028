import React, { useState, useEffect } from "react";
import "./App.css";
import { Alert } from "./components/alert";
import { CharacterPicker } from "./components/character-picker";
import { CharacterPortrait } from "./components/characterPortrait";
import { CorporationStructures } from "./components/corporation-structures";
import { Finance } from "./components/finance";
import { IndustryJobs } from "./components/industry-jobs";
import { SkillQueue } from "./components/skillQueue";
import { SSOButton } from "./components/ssoButton";
import AccessTokenHelper from "./helpers/access-token-helper";
import EnvironmentHelper from "./helpers/environment-helper";
import TestHelper from "./helpers/test-helper";
import CharacterService from "./services/character-service";
import ESI from "./services/esi";
import EVESSOService from "./services/evesso-service";
import NameLookup from "./services/name-lookup-service";

function App() {
  const [characterId, setCharacterId] = useState(
    CharacterService.getActiveCharacter()?.characterId
  );
  const [navigation, setNavigation] = useState(
    window.location.hash.split("#")[1]
  );
  const [namesLoaded, setNamesLoaded] = useState(false);

  if (!namesLoaded) {
    NameLookup.loadNames(() => {
      setNamesLoaded(true);
    });
  }

  if (!namesLoaded) {
    return (
      <p>Loading API data for the first time - this might take a moment....</p>
    );
  }

  // TODO: use random text here
  let input_str = "I am converting string to hashsdfgsdfgsdfgsdfgsdgf.";
  var encodedInputStr = EVESSOService.URLBase64(input_str);

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  }) as any;
  let ssoCode = params.code;

  if (ssoCode) {
    if (!EVESSOService.isLoadingAuth) {
      EVESSOService.isLoadingAuth = true;

      let xhr = new XMLHttpRequest();
      xhr.open("POST", "https://login.eveonline.com/v2/oauth/token");
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

      xhr.onload = () => {
        let response = JSON.parse(xhr.responseText);
        if (!response.access_token) {
          return;
        }

        console.log(response.access_token);

        let decodedToken = AccessTokenHelper.parseAccessToken(
          response.access_token
        );
        var tokenCharacterId = decodedToken.sub
          ? decodedToken.sub.split(":")[2]
          : "Error";
        let characterIdNumber = parseInt(tokenCharacterId);
        var tokenExpiration = new Date((decodedToken.exp as number) * 1000);

        ESI.getCharacterInfo(characterIdNumber).then((characterInfo) => {
          setCharacterId(characterIdNumber);

          CharacterService.addCharacter({
            characterId: characterIdNumber,
            corporationId: characterInfo.corporation_id,
            characterName: decodedToken.name ?? "",
            accessToken: response.access_token,
            accessTokenExpiration: tokenExpiration.toISOString(),
            refreshToken: response.refresh_token,
            scopes: decodedToken.scp ?? [],
          });
          window.location.href = window.location.href.split("?")[0];
        });
      };

      const clientId = encodeURIComponent(EnvironmentHelper.getEVEClientId());
      let data = `grant_type=authorization_code&client_id=${clientId}&code=${ssoCode}&code_verifier=${encodedInputStr}`;

      xhr.send(data);
    }
    return <p>Signing you in...</p>;
  }

  const handleChange = (newSelectedCharacter) => {
    setCharacterId(newSelectedCharacter);
  };

  const handleNavigation = (newNavigation) => {
    var hamburgerCheckbox = document.getElementById('toggler') as HTMLInputElement;
    if(hamburgerCheckbox.checked)
    {
      hamburgerCheckbox.checked = false;
    }
    setNavigation(newNavigation.target.href.split("#")[1]);
    return false;
  };

  function getBodyContentForNav(navigation: string) {
    switch (navigation) {
      case "skill-queue":
        return (
          <div className="flex-center">
            <CharacterPortrait characterId={characterId}></CharacterPortrait>
            <SkillQueue characterId={characterId}></SkillQueue>
          </div>
        );
      case "finance":
        return (
          <div className="flex-center">
            <Finance></Finance>
          </div>
        );
      case "industry":
        return (
          <div className="flex-center">
            <IndustryJobs></IndustryJobs>
          </div>
        );
      case "structures":
        return (
          <div className="flex-center">
            <CorporationStructures></CorporationStructures>
          </div>
        );
      default:
        return (
          <div className="flex-center">
            <CharacterPortrait characterId={characterId}></CharacterPortrait>
            <SkillQueue characterId={characterId}></SkillQueue>
          </div>
        );
    }
  }

  return (
    <div className="App">
      <div>
        <div className="mobile-top-bar">
          <input type="checkbox" className="toggler" id="toggler" />
          <div className="hamburger"><div></div></div>
          <ul className="menu">
          <br /><br /><br />
            <h4><a onClick={handleNavigation} href="#skill-queue">Skill Queue</a></h4>
            <h4><a onClick={handleNavigation} href="#finance">Finance</a></h4>
            <h4><a onClick={handleNavigation} href="#industry">Industry</a></h4>
            <h4><a onClick={handleNavigation} href="#structures">Structures</a></h4>
            <div>
              <CharacterPicker onChange={handleChange}></CharacterPicker>
            </div>
            <h4>
              <SSOButton></SSOButton>
            </h4>
          </ul>
        </div>
        <div className="top-bar">
          <h3 className="title">Jita Hangar</h3>
          <div className="flex-center-vertical">
            <a onClick={handleNavigation} href="#skill-queue">
              Skill Queue
            </a>
          </div>
          <div className="flex-center-vertical">
            <a onClick={handleNavigation} href="#finance">
              Finance
            </a>
          </div>
          <div className="flex-center-vertical">
            <a onClick={handleNavigation} href="#industry">
              Industry
            </a>
          </div>
          <div className="flex-center-vertical">
            <a onClick={handleNavigation} href="#structures">
              Structures
            </a>
          </div>
          <div className="flex-center">
            <p>Selected character:</p>
            <CharacterPicker onChange={handleChange}></CharacterPicker>
          </div>
          <div className="flex-center-vertical">
            <SSOButton></SSOButton>
          </div>
        </div>

        {getBodyContentForNav(navigation)}
        <Alert></Alert>
      </div>
    </div>
  );
}

export default App;
