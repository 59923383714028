import React, { useState, useEffect } from "react";
import EnvironmentHelper from "../helpers/environment-helper";
import EVESSOService from '../services/evesso-service';

export const SSOButton = () => {
  const [link, setLink] = useState(window.location.href);

  // TODO: Use 32 random bytes here instead.
  let input_str = "I am converting string to hashsdfgsdfgsdfgsdfgsdgf.";
  var encodedInputStr = EVESSOService.URLBase64(input_str);

  if (link.startsWith(window.location.href )) {
    EVESSOService.hash(encodedInputStr).then((hex) => {
      var encodedHash = EVESSOService.generatePKCEHash(hex);
      const hostname = encodeURIComponent(EnvironmentHelper.getHostName());
      const clientId = encodeURIComponent(EnvironmentHelper.getEVEClientId());
      const ssoScopes = [
        "esi-corporations.read_structures.v1",
        "esi-industry.read_character_jobs.v1",
        "esi-industry.read_corporation_jobs.v1",
        "esi-planets.read_customs_offices.v1",
        "esi-skills.read_skillqueue.v1",
        "esi-skills.read_skills.v1",
        "esi-wallet.read_character_wallet.v1",
        "esi-wallet.read_corporation_wallets.v1"
      ];
      const scopesUrlArgument = ssoScopes.join(" ");
      var ssoLink =
        `https://login.eveonline.com/v2/oauth/authorize/?response_type=code&redirect_uri=https%3A%2F%2F${hostname}%2F&client_id=${clientId}&scope=${scopesUrlArgument}&state=abc123&code_challenge_method=S256&code_challenge=${encodedHash}`;
      setLink(ssoLink);
    });
  }

  return <a href={link}>Login with EVE Online</a>;
};
