import React, { useState, useEffect } from "react";
import IndexDBHelper from "../helpers/indexed-db-helper";
import RelativeTime from "../helpers/relative-time-helper";
import IndustryJob from "../models/esi/industry-job";
import { SkillsResponse } from "../models/esi/skill";
import CharacterService from "../services/character-service";
import ESIService from "../services/esi-service";

export const IndustryJobs = (props) => {
  const [jobs, setJobs] = useState<Array<IndustryJob>>([]);
  const [slotCounts, setSlotCounts] = useState<Array<any>>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { characterId } = props;

  const characters = CharacterService.getAllCharacters();

  if (!characters) {
    return <p>No characters found.</p>;
  }

  if (jobs.length === 0) {
    if (isLoaded) {
      return <p>No jobs in queue.</p>;
    }
  }

  const industrySkillDictionary = {
    "3387": "Mass Production",
    "24625": "Advanced Mass Production",
    "3406": "Laboratory Operation",
    "24624": "Advanced Laboratory Operation",
    "45748": "Mass Reactions",
    "45749": "Advanced Mass Reactions"
  };

  if (!isLoaded) {
    let jobPromises: Array<Promise<IndustryJob[]>> = [];
    let skillsPromises: Array<Promise<any>> = [];
    characters.forEach((character) => {
      jobPromises.push(
        ESIService.getIndustryJobs(character.characterId, character.characterId)
      );
      skillsPromises.push(
        ESIService.getSkills(character.characterId, character.characterId).then(
          (response) => {
            return {
              characterName: character.characterName,
              characterId: character.characterId,
              response: response,
            };
          }
        )
      );
      jobPromises.push(
        ESIService.getCharacterInfo(
          character.characterId,
          character.characterId
        ).then((info) => {
          return ESIService.getCorporationIndustryJobs(
            info.corporation_id,
            character.characterId
          );
        })
      );
    });
    Promise.all(jobPromises).then((promiseResults) => {
      let newJobs: Array<IndustryJob> = [];
      promiseResults.forEach((result) => {
        newJobs = newJobs.concat(result);
      });
      let jobIds = {};
      newJobs = newJobs.filter((element) => {
        var isNew = jobIds[element.job_id] == null;
        jobIds[element.job_id] = true;
        return isNew;
      });
      newJobs.sort((a, b) => {
        return new Date(a.end_date) > new Date(b.end_date) ? 1 : -1;
      });
      Promise.all(skillsPromises).then((promiseResults) => {
        let loadedSlotCounts: Array<any> = [];
        promiseResults.forEach((result) => {
          let manufacturingJobs = newJobs.filter((job) => {
            return job.installer_id == result.characterId
                && job.activity_id === 1;
          }).length;
          let massProductionSkill = result.response.skills.filter(skill => {
            return skill.skill_id === 3387;
          })[0];
          let massProductionLevel = massProductionSkill ? massProductionSkill.trained_skill_level : 0;
          let advMassProductionSkill = result.response.skills.filter(skill => {
            return skill.skill_id === 24625;
          })[0];
          let advMassProductionLevel = advMassProductionSkill ? advMassProductionSkill.trained_skill_level : 0;
          let totalManufacturing = 1 + massProductionLevel + advMassProductionLevel;

          let reactionJobs = newJobs.filter((job) => {
            return job.installer_id == result.characterId
                && job.activity_id === 9;
          }).length;

          let massReactionsSkill = result.response.skills.filter(skill => {
            return skill.skill_id === 45748;
          })[0];
          let massReactionsLevel = massReactionsSkill ? massReactionsSkill.trained_skill_level : 0;
          let advMassReactionsSkill = result.response.skills.filter(skill => {
            return skill.skill_id === 45749;
          })[0];
          let advMassReactionsLevel = advMassReactionsSkill ? advMassReactionsSkill.trained_skill_level : 0;

          let totalReactions = 1 + massReactionsLevel + advMassReactionsLevel;

          let scienceJobs = newJobs.filter((job) => {
            return job.installer_id == result.characterId
                && (job.activity_id === 3 || job.activity_id === 4 || job.activity_id === 5 || job.activity_id === 8);
          }).length;

          let labOperationSkill = result.response.skills.filter(skill => {
            return skill.skill_id === 3406;
          })[0];
          let labOperationLevel = labOperationSkill ? labOperationSkill.trained_skill_level : 0;
          let advLabOperationSkill = result.response.skills.filter(skill => {
            return skill.skill_id === 24624;
          })[0];
          let advLabOperationLevel = advLabOperationSkill ? advLabOperationSkill.trained_skill_level : 0;

          let totalScience = 1 + labOperationLevel + advLabOperationLevel;

          loadedSlotCounts.push({
            character: result.characterName,
            remainingManufacturingSlots: totalManufacturing - manufacturingJobs,
            totalManufacturingSlots: totalManufacturing,
            remainingScienceSlots: totalScience - scienceJobs,
            totalScienceSlots: totalScience,
            remainingReactionSlots: totalReactions - reactionJobs,
            totalReactionSlots: totalReactions
          });
        });
        setSlotCounts(loadedSlotCounts);
      });
      setJobs(newJobs);
      setIsLoaded(true);
    });
  }

  const activityDictionary = {
    "1": "Manufacturing",
    "3": "TE Research",
    "4": "ME Research",
    "5": "Copying",
    "8": "Invention",
    "9": "Reaction",
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Character</th>
            <th>Manufacturing</th>
            <th>Science</th>
            <th>Reactions</th>
          </tr>
        </thead>
        <tbody>
        {slotCounts.map(function (slotInfo: any, index : number) {
          return (
            <tr key={slotInfo.character}>
              <td>{slotInfo.character}</td>
              <td>{slotInfo.remainingManufacturingSlots} / {slotInfo.totalManufacturingSlots}</td>
              <td>{slotInfo.remainingScienceSlots} / {slotInfo.totalScienceSlots}</td>
              <td>{slotInfo.remainingReactionSlots} / {slotInfo.totalReactionSlots}</td>
            </tr>
          );
        })}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Installer</th>
            <th>Activity</th>
            <th>Status</th>
            <th>Name</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(function (job: IndustryJob) {
            const completedStyle = new Date(Date.parse(job.end_date)) < new Date() ? {
              color: "green",
            } : {};
            let activityColor = "#4466FF";
            switch(job.activity_id)
            {
              case 1:
                activityColor = "#FFCC00";
                break;
              case 9:
                activityColor = "green";
                break;
            }
            const activityStyle = {
              color: activityColor,
            }
            return (
              <tr key={job.job_id}>
                <td>{job.installer_name}</td>
                <td style={activityStyle}>{activityDictionary[job.activity_id]}</td>
                <td>{job.status}</td>
                <td>{job.name}</td>
                <td style={completedStyle}>{new Date(Date.parse(job.end_date)).toLocaleString()}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
