
export default class AlertService
{
    static registeredAlertCallback : Function;

    public static alert(alertMessage : string)
    {
        if(AlertService.registeredAlertCallback)
        {
            AlertService.registeredAlertCallback(alertMessage);
        }
    }

    public static registerAlert(alertCallback : Function)
    {
        AlertService.registeredAlertCallback = alertCallback;
    }
}