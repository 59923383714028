import TestDataHelper from "../../helpers/test-data-helper";
import { Character } from "../character-service";
import ESI from "../esi";
import ESIService from "../esi-service";
import Observer from "./observer";

export default class TransactionObserver extends Observer {
  static observer = new TransactionObserver();

  public loadDataForCharacter(character : Character) {
    ESIService.refreshTokenIfNeeded(character.characterId).then(
      (accessToken) => {
        ESI.getWalletTransactions(
          character.characterId,
          accessToken
        ).then((transactions) => {
          this.setCachedResultForCharacter(character.characterId, transactions);
          this.setCharacterDataLoaded(character.characterId);
        });
      }
    );
  }

  public loadTestDataForCharacter(character: Character): void {
    this.setCachedResultForCharacter(character.characterId,
      TestDataHelper.getWalletTransactionsForCharacter(character.characterId));
    this.setCharacterDataLoaded(character.characterId);
  }
}
