import TestHelper from "../helpers/test-helper";

export default class CharacterService {
  public static characterInfo: CharacterInfo;

  public static addCharacter(character: Character) {
    if (!this.characterInfo) {
      this.loadCharacterInfo();
    }
    let duplicate = false;
    this.characterInfo.characters.forEach((existingCharacter) => {
      if (existingCharacter.characterId === character.characterId) {
        duplicate = true;
        existingCharacter.accessToken = character.accessToken;
        existingCharacter.refreshToken = character.refreshToken;
      }
    });
    if (duplicate) {
      this.characterInfo.characters = this.characterInfo.characters.filter(
        (existingCharacter) => {
          if (existingCharacter.characterId !== character.characterId) {
            return true;
          } else {
            return false;
          }
        }
      );
    }
    else{
      this.setActiveCharacter(character.characterId);
    }
    this.characterInfo.characters.push(character);
    this.saveCharacterInfo();
  }

  public static getActiveCharacter(): Character | null {
    if (!this.characterInfo) {
      this.loadCharacterInfo();
    }
    const activeCharacter = this.getCharacter(
      this.characterInfo.activeCharacterId
    );
    return activeCharacter;
  }

  public static setActiveCharacter(newActiveCharacterId: number): void {
    if (!this.characterInfo) {
      this.loadCharacterInfo();
    }
    this.characterInfo.activeCharacterId = newActiveCharacterId;
    this.saveCharacterInfo();
  }

  public static getCharacter(characterId: number): Character | null {
    if (!this.characterInfo) {
      this.loadCharacterInfo();
    }
    const searchedCharacter = this.characterInfo.characters.filter(
      (character) => {
        if (character.characterId === characterId) {
          return true;
        }
        return false;
      }
    );
    if (searchedCharacter.length === 0) {
      return null;
    }
    return searchedCharacter[0];
  }

  public static getAllCharacters(): Array<Character> {
    if (!this.characterInfo) {
      this.loadCharacterInfo();
    }
    return this.characterInfo.characters;
  }

  public static loadCharacterInfo() {
    if(TestHelper.isDemo())
    {
      let character = {
        characterId: 1,
        corporationId: 1000,
        characterName: "Peter Griffin",
        accessToken: "12345",
        accessTokenExpiration: "1234567",
        refreshToken: "refresh",
        scopes: ["Array<string>"]
      };
      let characterArray = [character];
      CharacterService.characterInfo = {
        activeCharacterId: 1,
        characters: characterArray as Array<Character>,
      } as CharacterInfo;
      return;
    }
    const savedCharacterInfo = localStorage.getItem("characterInfo");
    if (!savedCharacterInfo) {
      CharacterService.characterInfo = {
        activeCharacterId: 0,
        characters: [] as Array<Character>,
      } as CharacterInfo;
    } else {
      CharacterService.characterInfo = JSON.parse(savedCharacterInfo);
    }
  }

  public static saveCharacterInfo() {
    if(TestHelper.isDemo())
    {
      return;
    }
    localStorage.setItem(
      "characterInfo",
      JSON.stringify(CharacterService.characterInfo)
    );
  }
}

export interface Character {
  characterId: number;
  corporationId: number;
  characterName: string;
  accessToken: string;
  accessTokenExpiration: string;
  refreshToken: string;
  scopes: Array<string>;
}

export interface CharacterInfo {
  activeCharacterId: number;
  characters: Array<Character>;
}
