export default class EnvironmentHelper {
    public static isDevelopment() {
      if(window.location.host === "localhost:3000")
      {
        return true;
      }
      return false;
    }

    public static getEVEClientId(){
        if(EnvironmentHelper.isDevelopment())
        {
            return '28b6a51cf7d8470c89da408fe9e26e9f';
        }
        else{
            return 'd7d4bcbd9cc84d22894ad4a03fc40e7a';
        }
    }

    public static getHostName(){
        if(EnvironmentHelper.isDevelopment())
        {
            return 'localhost:3000';
        }
        else{
            return 'jitahangar.com';
        }
    }
  }
  