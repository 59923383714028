import TestDataHelper from "../../helpers/test-data-helper";
import { Character } from "../character-service";
import ESI from "../esi";
import ESIService from "../esi-service";
import Observer from "./observer";

export default class SkillQueueObserver extends Observer {
  static observer = new SkillQueueObserver();

  public loadDataForCharacter(character : Character) {
    ESIService.refreshTokenIfNeeded(character.characterId).then(
      (accessToken) => {
        ESI.getSkillQueue(
          character.characterId,
          accessToken
        ).then((queue) => {
          this.setCachedResultForCharacter(character.characterId, queue);
          this.setCharacterDataLoaded(character.characterId);
        });
      }
    );
  }

  public loadTestDataForCharacter(character : Character)
  {
    let skillQueue = TestDataHelper.getSkillQueueForCharacter(character.characterId);
    this.setCachedResultForCharacter(character.characterId, skillQueue);
    this.setCharacterDataLoaded(character.characterId);
  }
}
