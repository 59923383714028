import React, { useState, useEffect } from "react";
import CookieHelper from "../helpers/cookie-helper";
import IndexDBHelper from "../helpers/indexed-db-helper";
import CharacterService from "../services/character-service";

export const CharacterPortrait = (props) => {
  const [portraitUrl, setPortraitUrl] = useState(window.location.href);
  const { characterId } = props;

  const character = CharacterService.getCharacter(characterId);

  if (!character) {
    return <p>Character not found.</p>;
  }

  var xmlHttpRequest = new XMLHttpRequest();
  xmlHttpRequest.open(
    "GET",
    "https://esi.evetech.net/latest/characters/" + characterId + "/portrait/"
  );
  xmlHttpRequest.setRequestHeader(
    "Authorization",
    "Bearer " + character.accessToken
  );
  xmlHttpRequest.onload = () => {
    var portraitResponse = JSON.parse(xmlHttpRequest.responseText);
    setPortraitUrl(portraitResponse.px512x512);
  };

  xmlHttpRequest.send();

  return <img src={portraitUrl} alt="" height="512px" width="512px"></img>;
};
