import React, { useState, useEffect } from "react";
import AlertService from "../services/alert-service";

export const Alert = (props) => {
  const [alert, setAlert] = useState("");

  AlertService.registerAlert(setAlert)

  return (
    <div className="flex-center">
        <div className="alert">{alert}</div>
    </div>
  );};
