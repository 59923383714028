import TestDataHelper from "../../helpers/test-data-helper";
import { Character } from "../character-service";
import ESI from "../esi";
import ESIService from "../esi-service";
import Observer from "./observer";

export default class CustomsOfficeObserver extends Observer {
  static observer = new CustomsOfficeObserver();

  public loadDataForCharacter(character : Character) {
    ESIService.refreshTokenIfNeeded(character.characterId).then(
      (accessToken) => {
        ESI.getCharacterInfo(character.characterId)
          .then((characterInfo) => {
            ESI.getCustomsOffices(
              characterInfo.corporation_id,
              accessToken
            ).then((queue) => {
              this.setCachedResultForCharacter(character.characterId, queue);
              this.setCharacterDataLoaded(character.characterId);
            });
          });
        });
  }

  public loadTestDataForCharacter(character : Character)
  {
    this.setCachedResultForCharacter(character.characterId, []);
    this.setCharacterDataLoaded(character.characterId);
  }
}
